code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
.fade-in {
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
   -ms-animation: fadein 0.5s; /* Internet Explorer */
    -o-animation: fadein 0.5s; /* Opera < 12.1 */
       animation: fadein 0.5s;
}

@keyframes fadein {  from { opacity: 0; }  to   { opacity: 1; }}

/* Firefox < 16 */
@-moz-keyframes fadein {  from { opacity: 0; }  to   { opacity: 1; }}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {  from { opacity: 0; }  to   { opacity: 1; }}

/* Internet Explorer */
@-ms-keyframes fadein {  from { opacity: 0; }  to   { opacity: 1; }}

/* Opera < 12.1 */
@-o-keyframes fadein {  from { opacity: 0; }  to   { opacity: 1; }}
.btn-close {
  filter: invert(1);
}
.loading-space {
  height: 550px;
}
.form-group{
  margin-bottom: 1rem;
}
body.dark a:not(.btn):not(.nav-link):not(.dropdown-item):not(.jstree-anchor.jstree-clicked):hover {
  color: #3dd598;
}
.modal .modal-dialog .modal-content .modal-header button.close > span {
  font-size: 2rem;
  font-family: Arial, Helvetica, sans-serif;
}
.modal-backdrop.show {
  opacity: 0;
}
.modal {
  background-color: #000000c7;
}
.swal-overlay {
  background-color: #000000c7;
}
.fast.spinner-border {
  -webkit-animation: spinner-border .3s linear infinite;
  animation: spinner-border .3s linear infinite;
}
.fast {
  -webkit-animation: all .3s linear infinite;
  animation: all .3s linear infinite
}


.focus\:ring:focus {
  box-shadow: none !important;
}

.uploading-box {
  background-color: #202124c2;
  backdrop-filter: blur(10px);
}
.progress-bar-animated {
  -webkit-animation: progress-bar-stripes .1s linear infinite !important;
  animation: progress-bar-stripes .1s linear infinite !important;
}
body.dark .progress {
  background-color: #293134;
  height: 4px;
}
.rdt_TableCol_Sortable div{ font-size: .9rem;}

footer.fixed-bottom {
  z-index: 999;
}
.table-hover tbody tr:hover {
  background-color: rgb(255 255 255 / 8%);
}
.breadcrumb .breadcrumb-item:before {
  content:none
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: #3dd598;
  color: #000;
}
.nav-pills .nav-link.active {
  color: #000;
  background: #3dd598;
}
.loader { 
  width:100px; 
  border-radius:10px;
  border:4px solid transparent;
  position:relative;
  padding:1px;
}
.loader:before {
  content:'';
  border:1px solid #fff; 
  border-radius:10px;
  position:absolute;
  top:-4px; 
  right:-4px; 
  bottom:-4px; 
  left:-4px;
}
.loader .loaderBar { 
  position:absolute;
  border-radius:10px;
  top:0;
  right:100%;
  bottom:0;
  left:0;
  background:#3dd598; 
  width:0;
  animation:borealisBar .5s linear infinite;
}

@keyframes borealisBar {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:75%;
    width:25%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
}
.file-name{
  white-space: normal;
  color: #FFF;
}

.avatar {
    flex: none
}





/* carousel */

.carousel-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.carousel-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-content {
  display: flex;
  transition: all 250ms ease-out;
  -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
  scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}
.left-arrow, .right-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: white;
  border: 1px solid #ddd;
}

.left-arrow {
  left: -100px;
}

.right-arrow {
  right: -100px;
}
@media (hover: none) and (pointer: coarse) {
  .left-arrow, .right-arrow {
      display: none;
  }
}
.carousel-content.show-2 > * {
  width: 50%;
}

.carousel-content.show-3 > * {
  width: calc(100% / 3);
}

.carousel-content.show-4 > * {
  width: calc(100% / 4);
}
.discount {
  border-radius: 5px;
  padding: 20px;
  background-color: #293134;
  color: #ddd;
}

.discount:hover {
  background-color: #343f42;
}
.discount button {
  color: #000 !important;
  border-color: transparent !important;
  background-color: #3dd598  !important;
}

.discount>button:hover{
  box-shadow: 0px 0px 15px 0px #0005;
  background-color: #2b9269 !important;
  color: #FFF !important;
}
.discount .cost {
  font-size: 28px;
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
  padding-top: 1rem;
  margin: 2rem 0;
}
.discount .h5 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 3rem;
}
.discount p {
  margin-bottom: 1rem;
  font-size: 18px;
}

.point::-webkit-outer-spin-button,
.point::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.point[type=number] {
  -moz-appearance: textfield;
}

.dropdown-menu .dropdown-scroll {
  overflow-y: scroll;
}


.tooltips {
  background-color: #202124;
}
.tooltips-hidden {
  visibility: hidden;
  pointer-events: none;
}

.body-grid {
  position: relative;
  width: 100%;
  border-radius: inherit;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0;
}.form-check-label,.form-control-label  {
  margin-top: 3px;
  padding-bottom: 3.5px;
}

body.dark .modal-content .modal-header , body.dark .btn.btn-light{
  background-color: #293134 !important;
  border: none !important;
}
body.dark .modal-content {
  background-color: #202124 !important ;
}

body.dark .btn.btn-light:hover {
  background-color: #526369 !important;
  transition: all .1s !important ;
}
.drop-to-upload {
  border: dashed grey 4px;
  background-color: rgba(0,0,0,.8);
  position: fixed;
  top: 0;
  left: 0;
  height:100vh;
  width:100%;
  z-index: 9999;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.drop-to-upload span{
  color: #FFF;
  font-size: 2.5rem;
}
/* RÌ BON SAI */
@media (max-width: 1200px) {

  .layout-wrapper .content-wrapper .content-body .content {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.header ul.navbar-nav li.nav-item a.nav-link {
  padding: 0px !important;
  padding-right: 5px !important;
}

  .header .header-body-right {
      top: 50px;
  }
  .job-name {
      width: 300px !important;
  }
  .render-dashboard-table tr {
      display: grid;
  }
  .render-dashboard-table thead {
      display: none;
  }

  .discount {
    margin: 0px 0px 50px 0px;
    width: 100% !important;
  }
  
  .carousel-content {
    display: block;
  }
  .carousel-content > * {
    width: 100% !important;
  }
  .discount:hover {
    box-shadow: none;
  }
  .carousel-content .card-body {
    padding: 0 !important;
  }
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

.spin-this {
  animation-name: spin;
  animation-duration: 250ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}