.bg-login {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url('https://statics.superrendersfarm.com/app/assets/media/image/Renderfarm1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: opacity(.2);
  z-index: -1;
}
.logoLogin {
  height: 100%;
  width: 100%;
  position: fixed;
  text-align: center;
  padding: 50px;
  top: 0;
  left: 0;
}
.login {
    color: #F0F0F0;
    font-size: 1rem;
  }

 
  .mask.rgba-black-light {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 99999; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }

  /* LOGIN FORM */
  .form-5 {
    /* Size and position */
    width: 100%;
    position: relative;
    overflow: hidden;
    /* Styles */
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0,0,0,0.1), 0 3px 2px rgba(0,0,0,0.1);
  }
  
  
  .form-5 input[type=email],
  .form-5 input[type=password] {
    /* Size and position */
    width: 100%;
    height: 50px !important;
  
    /* Styles */
    border: none; /* Remove the default border */
  
    /* Font styles */
    text-indent: 10px;
    color: #FFF;
    font-size: 14px;       
  }
  .form-5 input::placeholder {
    color: #b8b8b8 !important;
    font-size: 12px;
  }

  
  .form-5 .label-email {
    position:absolute;
    left: 20px;
    top: 5px;
    font-size: small;
    text-shadow:0 0 5px #000000 !important;
  }
  .form-5 .label-password {
    position:absolute;
    left: 20px;
    bottom:25px;
    font-size: small;
    text-shadow: 0 0 5px #000000 !important;
  }
  
  .form-5 input[type=password] {
    border-radius: 0 0 0 5px;
    padding-top: 25px
  }

  .form-5 input[type=email] {
    border-radius: 5px 0 0 0;
    padding-top: 25px
  } 

  .signup-button{
    background-color:#3c555e ;

  }
  .signup-button:hover{
    background-color:#203a44 ;

  }
  @media (max-width: 1200px) {
    .login {
        padding: 2rem;
        border-radius: 10px;
        font-size: 1rem;
      }
  
   
    }