@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap);
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.fade-in {
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
       animation: fadein 0.5s;
}

@keyframes fadein {  from { opacity: 0; }  to   { opacity: 1; }}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {  from { opacity: 0; }  to   { opacity: 1; }}

/* Internet Explorer */

/* Opera < 12.1 */
.btn-close {
  -webkit-filter: invert(1);
          filter: invert(1);
}
.loading-space {
  height: 550px;
}
.form-group{
  margin-bottom: 1rem;
}
body.dark a:not(.btn):not(.nav-link):not(.dropdown-item):not(.jstree-anchor.jstree-clicked):hover {
  color: #3dd598;
}
.modal .modal-dialog .modal-content .modal-header button.close > span {
  font-size: 2rem;
  font-family: Arial, Helvetica, sans-serif;
}
.modal-backdrop.show {
  opacity: 0;
}
.modal {
  background-color: #000000c7;
}
.swal-overlay {
  background-color: #000000c7;
}
.fast.spinner-border {
  -webkit-animation: spinner-border .3s linear infinite;
  animation: spinner-border .3s linear infinite;
}
.fast {
  -webkit-animation: all .3s linear infinite;
  animation: all .3s linear infinite
}


.focus\:ring:focus {
  box-shadow: none !important;
}

.uploading-box {
  background-color: #202124c2;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
.progress-bar-animated {
  -webkit-animation: progress-bar-stripes .1s linear infinite !important;
  animation: progress-bar-stripes .1s linear infinite !important;
}
body.dark .progress {
  background-color: #293134;
  height: 4px;
}
.rdt_TableCol_Sortable div{ font-size: .9rem;}

footer.fixed-bottom {
  z-index: 999;
}
.table-hover tbody tr:hover {
  background-color: rgb(255 255 255 / 8%);
}
.breadcrumb .breadcrumb-item:before {
  content:none
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: #3dd598;
  color: #000;
}
.nav-pills .nav-link.active {
  color: #000;
  background: #3dd598;
}
.loader { 
  width:100px; 
  border-radius:10px;
  border:4px solid transparent;
  position:relative;
  padding:1px;
}
.loader:before {
  content:'';
  border:1px solid #fff; 
  border-radius:10px;
  position:absolute;
  top:-4px; 
  right:-4px; 
  bottom:-4px; 
  left:-4px;
}
.loader .loaderBar { 
  position:absolute;
  border-radius:10px;
  top:0;
  right:100%;
  bottom:0;
  left:0;
  background:#3dd598; 
  width:0;
  -webkit-animation:borealisBar .5s linear infinite;
          animation:borealisBar .5s linear infinite;
}

@-webkit-keyframes borealisBar {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:75%;
    width:25%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
}

@keyframes borealisBar {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:75%;
    width:25%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
}
.file-name{
  white-space: normal;
  color: #FFF;
}

.avatar {
    -webkit-flex: none;
            flex: none
}





/* carousel */

.carousel-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.carousel-wrapper {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  position: relative;
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-content {
  display: -webkit-flex;
  display: flex;
  transition: all 250ms ease-out;
  -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
  scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  width: 100%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}
.left-arrow, .right-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: white;
  border: 1px solid #ddd;
}

.left-arrow {
  left: -100px;
}

.right-arrow {
  right: -100px;
}
@media (hover: none) and (pointer: coarse) {
  .left-arrow, .right-arrow {
      display: none;
  }
}
.carousel-content.show-2 > * {
  width: 50%;
}

.carousel-content.show-3 > * {
  width: calc(100% / 3);
}

.carousel-content.show-4 > * {
  width: calc(100% / 4);
}
.discount {
  border-radius: 5px;
  padding: 20px;
  background-color: #293134;
  color: #ddd;
}

.discount:hover {
  background-color: #343f42;
}
.discount button {
  color: #000 !important;
  border-color: transparent !important;
  background-color: #3dd598  !important;
}

.discount>button:hover{
  box-shadow: 0px 0px 15px 0px #0005;
  background-color: #2b9269 !important;
  color: #FFF !important;
}
.discount .cost {
  font-size: 28px;
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
  padding-top: 1rem;
  margin: 2rem 0;
}
.discount .h5 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 3rem;
}
.discount p {
  margin-bottom: 1rem;
  font-size: 18px;
}

.point::-webkit-outer-spin-button,
.point::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.point[type=number] {
  -moz-appearance: textfield;
}

.dropdown-menu .dropdown-scroll {
  overflow-y: scroll;
}


.tooltips {
  background-color: #202124;
}
.tooltips-hidden {
  visibility: hidden;
  pointer-events: none;
}

.body-grid {
  position: relative;
  width: 100%;
  border-radius: inherit;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0;
}.form-check-label,.form-control-label  {
  margin-top: 3px;
  padding-bottom: 3.5px;
}

body.dark .modal-content .modal-header , body.dark .btn.btn-light{
  background-color: #293134 !important;
  border: none !important;
}
body.dark .modal-content {
  background-color: #202124 !important ;
}

body.dark .btn.btn-light:hover {
  background-color: #526369 !important;
  transition: all .1s !important ;
}
.drop-to-upload {
  border: dashed grey 4px;
  background-color: rgba(0,0,0,.8);
  position: fixed;
  top: 0;
  left: 0;
  height:100vh;
  width:100%;
  z-index: 9999;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.drop-to-upload span{
  color: #FFF;
  font-size: 2.5rem;
}
/* RÌ BON SAI */
@media (max-width: 1200px) {

  .layout-wrapper .content-wrapper .content-body .content {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.header ul.navbar-nav li.nav-item a.nav-link {
  padding: 0px !important;
  padding-right: 5px !important;
}

  .header .header-body-right {
      top: 50px;
  }
  .job-name {
      width: 300px !important;
  }
  .render-dashboard-table tr {
      display: grid;
  }
  .render-dashboard-table thead {
      display: none;
  }

  .discount {
    margin: 0px 0px 50px 0px;
    width: 100% !important;
  }
  
  .carousel-content {
    display: block;
  }
  .carousel-content > * {
    width: 100% !important;
  }
  .discount:hover {
    box-shadow: none;
  }
  .carousel-content .card-body {
    padding: 0 !important;
  }
}

@-webkit-keyframes spin {
  from {
      -webkit-transform:rotate(0deg);
              transform:rotate(0deg);
  }
  to {
      -webkit-transform:rotate(360deg);
              transform:rotate(360deg);
  }
}

@keyframes spin {
  from {
      -webkit-transform:rotate(0deg);
              transform:rotate(0deg);
  }
  to {
      -webkit-transform:rotate(360deg);
              transform:rotate(360deg);
  }
}

.spin-this {
  -webkit-animation-name: spin;
          animation-name: spin;
  -webkit-animation-duration: 250ms;
          animation-duration: 250ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; 
}


.logo img {
  width: 50px; }

.logo a {
  font-size: 1rem;
  color: #FFF !important;
  font-weight: 1000; }

.nav-user-cost {
  color: #3dd598;
  font-family: 'Courier New', Courier, monospace;
  font-size: .8rem; }
  .nav-user-cost span {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; }

@-webkit-keyframes swing {
  0%,
  30%,
  50%,
  70%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  10% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg); }
  40% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); } }

@keyframes swing {
  0%,
  30%,
  50%,
  70%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  10% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg); }
  40% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); } }

.pro-sidebar {
  color: #adadad;
  height: 100%;
  width: 270px;
  min-width: 270px;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: fixed !important;
  z-index: 1001; }

.pro-sidebar > .pro-sidebar-inner {
  height: 100%;
  position: relative;
  z-index: 1001; }

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding: 5px 0 !important; }

.pro-sidebar > .pro-sidebar-inner > img.sidebar-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  opacity: 0.3;
  left: 0;
  top: 0;
  z-index: 100; }

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  z-index: 101;
  padding: 0px 10px 0px 15px;
  background-color: #202124; }

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
  -webkit-flex-grow: 1;
          flex-grow: 1; }

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul {
  list-style-type: none;
  padding: 0;
  margin: 0; }

.pro-sidebar .overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #20212480;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  z-index: 1000;
  display: none;
  opacity: 1 !important; }

.pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px; }

.pro-sidebar.rtl {
  text-align: right;
  direction: rtl; }

@media (max-width: 480px) {
  .pro-sidebar.xs {
    position: fixed;
    left: -270px; }
  .pro-sidebar.xs.collapsed {
    left: -80px; }
  .pro-sidebar.xs.toggled {
    left: 0; }
  .pro-sidebar.xs.toggled .overlay {
    display: block; }
  .pro-sidebar.xs.rtl {
    left: auto;
    right: -270px; }
  .pro-sidebar.xs.rtl.collapsed {
    left: auto;
    right: -80px; }
  .pro-sidebar.xs.rtl.toggled {
    left: auto;
    right: 0; } }

@media (max-width: 576px) {
  .pro-sidebar.sm {
    position: fixed;
    left: -270px; }
  .pro-sidebar.sm.collapsed {
    left: -80px; }
  .pro-sidebar.sm.toggled {
    left: 0; }
  .pro-sidebar.sm.toggled .overlay {
    display: block; }
  .pro-sidebar.sm.rtl {
    left: auto;
    right: -270px; }
  .pro-sidebar.sm.rtl.collapsed {
    left: auto;
    right: -80px; }
  .pro-sidebar.sm.rtl.toggled {
    left: auto;
    right: 0; } }

@media (max-width: 768px) {
  .pro-sidebar.md {
    position: fixed;
    left: -270px; }
  .pro-sidebar.md.collapsed {
    left: -80px; }
  .pro-sidebar.md.toggled {
    left: 0; }
  .pro-sidebar.md.toggled .overlay {
    display: block; }
  .pro-sidebar.md.rtl {
    left: auto;
    right: -270px; }
  .pro-sidebar.md.rtl.collapsed {
    left: auto;
    right: -80px; }
  .pro-sidebar.md.rtl.toggled {
    left: auto;
    right: 0; } }

@media (max-width: 992px) {
  .pro-sidebar.lg {
    position: fixed;
    left: -270px; }
  .pro-sidebar.lg.collapsed {
    left: -80px; }
  .pro-sidebar.lg.toggled {
    left: 0; }
  .pro-sidebar.lg.toggled .overlay {
    display: block; }
  .pro-sidebar.lg.rtl {
    left: auto;
    right: -270px; }
  .pro-sidebar.lg.rtl.collapsed {
    left: auto;
    right: -80px; }
  .pro-sidebar.lg.rtl.toggled {
    left: auto;
    right: 0; } }

@media (max-width: 1200px) {
  .pro-sidebar.xl {
    position: fixed;
    left: -270px; }
  .pro-sidebar.xl.collapsed {
    left: -80px; }
  .pro-sidebar.xl.toggled {
    left: 0; }
  .pro-sidebar.xl.toggled .overlay {
    display: block; }
  .pro-sidebar.xl.rtl {
    left: auto;
    right: -270px; }
  .pro-sidebar.xl.rtl.collapsed {
    left: auto;
    right: -80px; }
  .pro-sidebar.xl.rtl.toggled {
    left: auto;
    right: 0; } }

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.pro-sidebar .pro-menu {
  padding-top: 10px;
  padding-bottom: 10px; }

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  position: relative;
  background-color: #2b2b2b; }

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 15px;
  padding-bottom: 15px; }

.pro-sidebar .pro-menu a {
  text-decoration: none;
  color: #adadad; }

.pro-sidebar .pro-menu a:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent; }

.pro-sidebar .pro-menu a:hover {
  color: #d8d8d8; }

.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 15px; }

.pro-sidebar .pro-menu .pro-menu-item.active {
  color: #d8d8d8; }

.pro-sidebar .pro-menu div div ul li.pro-menu-item:hover {
  background-color: #d8d8d85d;
  transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }

.pro-sidebar .pro-menu .pro-menu-item .suffix-wrapper {
  opacity: 1;
  transition: opacity 0.2s; }

.pro-sidebar .pro-menu .pro-menu-item .prefix-wrapper {
  display: -webkit-flex;
  display: flex;
  margin-right: 5px;
  opacity: 1;
  transition: opacity 0.2s; }

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 8px 35px 8px 20px;
  cursor: pointer; }

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
  outline: none;
  color: #d8d8d8; }

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  margin-right: 10px;
  font-size: 14px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block; }

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper .pro-icon {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1; }

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content > a.active {
  color: #3dd598 !important; }

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content > a:hover {
  color: #00ff99 !important; }

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: #d8d8d8; }

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover .pro-icon-wrapper .pro-icon {
  -webkit-animation: swing ease-in-out 0.5s 1 alternate;
          animation: swing ease-in-out 0.5s 1 alternate; }

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item:before {
  background: #adadad; }

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  display: inline-block;
  border-style: solid;
  border-width: 0 2px 2px 0;
  padding: 2.5px;
  vertical-align: middle;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item:before {
  background: transparent !important; }

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open {
  background-color: #adadad75;
  color: #FFF; }

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  margin-left: 10px; }

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item > .pro-icon-wrapper {
  display: none; }

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item .pro-arrow-wrapper {
  display: none; }

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  background-color: #2b2b2b; }

.pro-sidebar .pro-menu.square .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  border-radius: 0; }

.pro-sidebar .pro-menu.round .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  border-radius: 4px; }

.pro-sidebar .pro-menu.circle .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  border-radius: 50%; }

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item {
  position: relative; }

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-item > .suffix-wrapper,
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-item > .prefix-wrapper {
  opacity: 0; }

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item > .pro-inner-list-item {
  background-color: #2b2b2b;
  z-index: 111; }

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer; }

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu {
  position: relative; }

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-item {
  pointer-events: none; }

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
  display: none; }

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item {
  height: auto !important;
  position: fixed;
  visibility: hidden;
  min-width: 220px;
  max-width: 270px;
  background-color: transparent;
  max-height: 100%;
  padding-left: 3px; }

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item.has-arrow {
  padding-left: 10px; }

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
  max-height: 100vh;
  overflow-y: auto;
  background-color: #2b2b2b;
  padding-left: 20px;
  border-radius: 4px; }

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover > .pro-inner-list-item {
  transition: visibility, -webkit-transform 0.3s;
  transition: visibility, transform 0.3s;
  transition: visibility, transform 0.3s, -webkit-transform 0.3s;
  visibility: visible; }

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover .pro-icon-wrapper .pro-icon {
  -webkit-animation: swing ease-in-out 0.5s 1 alternate;
          animation: swing ease-in-out 0.5s 1 alternate; }

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 30px 8px 5px; }

.pro-sidebar.rtl .pro-menu .pro-menu-item .prefix-wrapper {
  margin-right: 0;
  margin-left: 5px; }

.pro-sidebar.rtl .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 8px 20px 8px 35px; }

.pro-sidebar.rtl .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  margin-right: 0;
  margin-left: 10px; }

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
  right: auto;
  left: 20px; }

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg); }

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 0;
  padding-right: 20px; }

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 15px 8px 30px; }

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  margin-right: 0;
  margin-left: 15px; }

.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item {
  padding-left: 0;
  padding-right: 3px; }

.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item.has-arrow {
  padding-right: 10px; }

.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
  padding-left: 0;
  padding-right: 20px; }

.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.rtl.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 5px 8px 30px; }

.popper-arrow {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent; }

.popper-element[data-popper-placement^='left'] > .popper-arrow {
  right: 0;
  border-right: 7px solid #2b2b2b; }

.popper-element[data-popper-placement^='right'] > .popper-arrow {
  left: 0;
  border-left: 7px solid #2b2b2b; }

.react-slidedown.transitioning {
  overflow-y: hidden; }

.react-slidedown.closed {
  display: none; }

.tab-content li a:not(.btn):not(.nav-link):not(.dropdown-item):not(.jstree-anchor.jstree-clicked) {
  color: #7fffaa; }

.job-name {
  width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.animate-icon {
  transition: all .1s linear; }

.animate-icon.down {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg); }

.fgINsU {
  -webkit-flex: auto !important;
  flex: auto !important; }

.jZBGyK {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 22px;
  color: #FFFFFF;
  background-color: #424242;
  min-height: 56px;
  padding-left: 16px;
  padding-right: 16px !important; }

.ffLupP {
  -webkit-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end; }

.brc-spaces:hover {
  background-color: #293134; }

table.dataTable {
  overflow: hidden; }

.folder-srf:hover {
  box-shadow: 0px 0px 25px 0px #333;
  background-color: #3b6d66 !important;
  transition: all .1s; }

.text-to-dark {
  color: #000 !important; }

.folder-srf > i {
  padding: 10px 0px 0px 0px !important; }

.moreAction {
  opacity: 0; }

.folder-srf {
  font-family: Roboto, sans-serif;
  font-size: .85rem; }

.folderGrid:hover > .action-srf > .moreAction {
  opacity: 1; }

.folderGrid {
  position: relative; }

.folderGrid:hover > .action-srf > .hide-on-hover {
  display: none; }

.action-srf {
  position: absolute;
  right: 20px;
  top: 15px; }

.dropleft.moreAction.show {
  opacity: 1; }

#table-files a {
  color: #FFF; }

.breadcrumb-item > a {
  color: #FFF !important; }

a:not(.btn):not(.nav-link):not(.dropdown-item):not(.jstree-anchor.jstree-clicked):hover > figure > span.avatar-title {
  box-shadow: 0px 0px 20px 1px #3dd598 !important;
  transition: all .1s; }

a:not(.btn):not(.nav-link):not(.dropdown-item):not(.jstree-anchor.jstree-clicked):hover > span > span.file-name {
  color: #3dd598 !important;
  transition: all .1s; }

.swal-modal {
  background-color: #293134  !important; }

.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #202124;
  font-size: 16px;
  font-weight: 100;
  border: 1px solid #202124;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
  width: 95%; }

.swal-button:hover {
  background-color: #414141 !important;
  border: 1px solid #444444;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.3);
  transition: all .2s; }

.swal-button-container {
  width: 50%;
  margin: auto;
  text-align: center; }

.swal-button:focus {
  outline: none;
  box-shadow: none !important; }

.swal-button:active {
  background-color: #575757; }

.swal-footer {
  display: -webkit-flex;
  display: flex;
  width: 100%; }

.analyze-modal {
  z-index: 2000; }


.bg-login {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url("https://statics.superrendersfarm.com/app/assets/media/image/Renderfarm1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-filter: opacity(0.2);
          filter: opacity(0.2);
  z-index: -1; }

.logoLogin {
  height: 100%;
  width: 100%;
  position: fixed;
  text-align: center;
  padding: 50px;
  top: 0;
  left: 0; }

.login {
  color: #F0F0F0;
  font-size: 1rem; }

.mask.rgba-black-light {
  position: fixed;
  /* Sit on top of the page content */
  display: none;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 99999;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */ }

/* LOGIN FORM */
.form-5 {
  /* Size and position */
  width: 100%;
  position: relative;
  overflow: hidden;
  /* Styles */
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1), 0 3px 2px rgba(0, 0, 0, 0.1); }

.form-5 input[type=email],
.form-5 input[type=password] {
  /* Size and position */
  width: 100%;
  height: 50px !important;
  /* Styles */
  border: none;
  /* Remove the default border */
  /* Font styles */
  text-indent: 10px;
  color: #FFF;
  font-size: 14px; }

.form-5 input::-webkit-input-placeholder {
  color: #b8b8b8 !important;
  font-size: 12px; }

.form-5 input:-ms-input-placeholder {
  color: #b8b8b8 !important;
  font-size: 12px; }

.form-5 input::placeholder {
  color: #b8b8b8 !important;
  font-size: 12px; }

.form-5 .label-email {
  position: absolute;
  left: 20px;
  top: 5px;
  font-size: small;
  text-shadow: 0 0 5px #000000 !important; }

.form-5 .label-password {
  position: absolute;
  left: 20px;
  bottom: 25px;
  font-size: small;
  text-shadow: 0 0 5px #000000 !important; }

.form-5 input[type=password] {
  border-radius: 0 0 0 5px;
  padding-top: 25px; }

.form-5 input[type=email] {
  border-radius: 5px 0 0 0;
  padding-top: 25px; }

.signup-button {
  background-color: #3c555e; }

.signup-button:hover {
  background-color: #203a44; }

@media (max-width: 1200px) {
  .login {
    padding: 2rem;
    border-radius: 10px;
    font-size: 1rem; } }

