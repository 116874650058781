.tab-content li a:not(.btn):not(.nav-link):not(.dropdown-item):not(.jstree-anchor.jstree-clicked) {
    color: #7fffaa ;
}
.job-name {
    width: 500px;
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
}


.animate-icon{
    -moz-transition: all .1s linear;
    -webkit-transition: all .1s linear;
    transition: all .1s linear;
}

.animate-icon.down{
    -moz-transform:rotate(+90deg);
    -webkit-transform:rotate(+90deg);
}
