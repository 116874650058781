.fgINsU {
-webkit-flex:auto !important;
-ms-flex:auto !important;
flex:auto !important;
}
.jZBGyK {
position: relative;
box-sizing: border-box;
overflow: hidden;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-flex: 1 1 auto;
-ms-flex: 1 1 auto;
flex: 1 1 auto;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: justify;
-webkit-justify-content: space-between;
-ms-flex-pack: justify;
justify-content: space-between;
width: 100%;
-webkit-flex-wrap: wrap;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
font-size: 22px;
color: #FFFFFF;
background-color: #424242;
min-height: 56px;
padding-left: 16px;
padding-right: 16px !important;
}
.ffLupP {
-webkit-flex: 1 0 auto;
-ms-flex: 1 0 auto;
flex: 1 0 auto;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: end;
-webkit-justify-content: flex-end;
-ms-flex-pack: end;
justify-content: flex-end;
}
.brc-spaces:hover {
background-color: #293134 ;
}
table.dataTable {
overflow: hidden;
}

.folder-srf:hover {
box-shadow: 0px 0px 25px 0px #333;
background-color: #3b6d66 !important;
transition: all .1s;
}

.text-to-dark { 
  color : #000 !important ;
}
.folder-srf > i  {
padding: 10px 0px 0px 0px !important;
}
.moreAction{
opacity: 0;
    
}
.folder-srf{
  font-family: Roboto, sans-serif;
  font-size: .85rem;
}
.folderGrid:hover > .action-srf >.moreAction{
opacity: 1;

}
.folderGrid {
  position: relative;
}
.folderGrid:hover > .action-srf >.hide-on-hover{
  display: none;  
}
.action-srf {
position: absolute;
right: 20px;
top: 15px;
}
.dropleft.moreAction.show {
opacity: 1;

}
#table-files a{
    color: #FFF;
}
.breadcrumb-item > a {
    color: #FFF !important;
}

a:not(.btn):not(.nav-link):not(.dropdown-item):not(.jstree-anchor.jstree-clicked):hover>figure>span.avatar-title {
    box-shadow: 0px 0px 20px 1px #3dd598 !important ;
    transition: all .1s;
}
a:not(.btn):not(.nav-link):not(.dropdown-item):not(.jstree-anchor.jstree-clicked):hover>span>span.file-name {
    color: #3dd598 !important;
    transition: all .1s;
}
.swal-modal {
  background-color:#293134  !important;
}
.swal-button {
    padding: 7px 19px;
    border-radius: 2px;
    background-color: #202124;
    font-size: 16px;
    font-weight: 100;
    border: 1px solid #202124;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
    width: 95%;

  }
  .swal-button:hover {
    background-color: #414141 !important;
    border: 1px solid #444444;
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.3);
    transition: all .2s;
  }
  .swal-button-container {
    width: 50%;
    margin: auto;
    text-align: center;
}
.swal-button:focus {
    outline: none;
    box-shadow: none !important;
}

.swal-button:active {
    background-color: #575757;
}

.swal-footer {
    display: flex;
    width: 100%;
}

.analyze-modal {
  z-index: 2000;
}
